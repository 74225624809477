import { Stack } from "@mui/material"
import { useCopy } from "data/copy"
import consultancyImage from "assets/consultancy.jpeg";
import Slide from "components/slide/Slide";
import Project from "./Project";

interface ProjectsProps {};



export default function Projects(props: ProjectsProps) {
    const copy = useCopy();

    return <Slide 
        title={copy.consultancy.projects.title} dark reverse 
        id="projects"
        img={consultancyImage}
        nextScroll={{label: copy.consultancy.tools.title, id: "tools"}}

    >
        <Stack direction="column">
            {
                copy.consultancy.projects.projects.map((project, index) => {
                    return <Project project={project} key={index.toString()} index={index}/>

                })
            }
        </Stack>
    </Slide>
}