import { BoxProps, Grid, SxProps } from "@mui/material";
import { ReactElement } from "react"

interface CoverProps {
    img: string
    children: ReactElement;
    sx?: SxProps<BoxProps>
};

const makeStyles = (img: string) => {
    return {
        box: {
            height: "100%",
            minHeight: "101vh",
        },
        background: {
            width: "100%",
            height: "100%",
            background: `url(${img})`,
            backgroundSize: "cover",
        },
        coverContent: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
            justifyContent: "center",
            flexDirection: "row",
            display: "flex"
        }
    }
}

export default function Cover(props: CoverProps) {
    const styles = makeStyles(props.img)

    return <Grid container sx={[styles.background, styles.box, props.sx ?? {} as any]}>
        <Grid item xs={12} sx={{zIndex: 1, backdropFilter: "brightness(30%)"}}>{props.children}</Grid>
    </Grid>
};