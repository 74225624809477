import Slide from "components/slide/Slide";
import { useCopy } from "data/copy";
import solutionsImg from "assets/solutions.jpg";
import { Button, Fade, Grid } from "@mui/material";
import IntroText from "./IntroText";
import Subtitle from "components/subtitle/Subtitle";
import { themeColors } from "features/theme/colors";
import { useScroll } from "hooks/scroll";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
    navigate?: boolean
    home?: boolean
}

const styles = {
    button: {
        color: themeColors.primary.contrastText,
        borderColor: themeColors.primary.contrastText,
    },
    buttonStack: {
        pb: {xs: 2, lg: 4}
    }
};

export default function Header(props: HeaderProps) {
    const { scrollTo } = useScroll();
    const copy = useCopy();
    const navigate = useNavigate();

    return <Slide 
        id="solutions"
        title={copy.pages.solutions}
        dark reverse
        img={solutionsImg}
        nextScroll={props.home ? undefined : {label: copy.solutions.customerCases, id: "foodlose"}}
    >
        <Fade in={true} timeout={1500}>
            <Grid container spacing={4} px={{xs: 2, lg: 8}}>
                <Grid item xs={12}>
                    <IntroText />
                </Grid>
                <Grid item xs={12}>
                    <Subtitle>{copy.solutions.discoverMore}</Subtitle>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={styles.buttonStack} spacing={{xs: 2, lg: 8}} justifyContent={{xs: "center", lg: "flex-start"}}>
                        <Grid item>
                            <Button 
                                variant="outlined" 
                                sx={styles.button}
                                onClick={() => {props.navigate ? navigate("/solutions#cases") : scrollTo("cases")}}
                            >{copy.solutions.customerCases}</Button>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="outlined" 
                                sx={styles.button}
                                onClick={() => {props.navigate ? navigate("/solutions#approach") : scrollTo("approach")}}
                            >{copy.solutions.ourApproach}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fade>
    </Slide>
}