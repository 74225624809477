import { AppBar, IconButton, Slide, Stack, Link } from "@mui/material"
import { useEffect, useState } from "react"
import IRoute from "routes/route"
import logo from "assets/logoNoText.svg";
import { Menu } from "@mui/icons-material";
import { themeColors } from "features/theme/colors";
import { useNavigate } from "react-router-dom";

interface MobileHeaderProps {
    transparent?: boolean
    routes: IRoute[]
}

const styles = {
    menuBar: {
        display: "absolute",
        left: 0, 
        height: "100vh",
        backgroundColor: themeColors.primary.contrastText,
        opacity: 0.8
    },
    menuLink: {
        px: 3,
        py: 2,
        fontSize: 24,
        textDecoration: "none"
    }
}

export default function MobileHeader(props: MobileHeaderProps) {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])
    
    return <AppBar
        position="fixed"
        sx={{
            zIndex: 10,
            backgroundColor: props.transparent && !open && scrollPosition < 100 ? "transparent" : themeColors.primary.dark,
        }}
        >
        <Stack direction="row" justifyContent={"space-between"}>
            <img src={logo} width="60px" alt="AG IT Solutions logo" onClick={() => navigate("/")}/>
            <IconButton onClick={() => setOpen(!open)}>
                <Menu htmlColor={themeColors.primary.contrastText} fontSize="large" />
            </IconButton>
        </Stack>
            <Slide in={open} mountOnEnter={true} unmountOnExit={true}>
                <Stack sx={styles.menuBar} direction="column" py={2}>
                    {
                        props.routes.map((value: IRoute) => {
                            return <Link href={value.path} sx={styles.menuLink} key={value.name}>{value.name.toUpperCase()}</Link>
                        })
                    }
                </Stack>
            </Slide>
    </AppBar>
}