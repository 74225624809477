import { useCopy } from "data/copy";
import privacyImg from "assets/privacy.jpeg"
import Slide from "components/slide/Slide";
import Policy from "./Policy";

export interface PrivacyPolicyProps {};

export default function PrivacyPolicy(props: PrivacyPolicyProps) {
    const copy = useCopy();

    return <Slide title={copy.privacy.policyTitle} dark id={"privacy"} img={privacyImg}>
        <Policy policy={copy.privacy.privacyPolicy} />
    </Slide>

}