import { Stack } from "@mui/material";
import Intro from "./Intro";
import Coffee from "./Coffee";
import Appetizer from "./Appetizer";
import Meat from "./Meat";
import ReadyToServe from "./ReadyToServe";

interface ApproachProps {};

export default function Approach(props: ApproachProps) {
    return <Stack direction="column" id="approach" width={"100%"}>
    <Intro />
    <Coffee />
    <Appetizer />
    <Meat />
    <ReadyToServe />
</Stack>
}