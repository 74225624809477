import { Box, IconButton, Stack, Typography } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { useScroll } from "hooks/scroll";
import { useEffect, useMemo, useState } from "react";
import { themeColors } from "features/theme/colors";
import { useCopy } from "data/copy";

interface FloatingScrollerProps {
    id: string
    nextScroll: string
    label: string
    color?: string
};

export function useOnScreen(id: string) {

    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = useMemo(() => new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting ?? false)
    ), [])
  
  
    useEffect(() => {
        const element = document.getElementById(id);
        if (element) {
            observer.observe(element)
        }
      
      return () => observer.disconnect()
    }, [id, observer])
  
    return isIntersecting
  }

const styles = {
    button: {
        backgroundColor: "#DDDDDD",
    },
    floating: {
        zIndex: 10,
        position: "fixed",
        bottom: "20px",
        right: "20px",
    },
    box: {
        alignItems: "flex-end",
        textColor: themeColors.primary.contrastText
    },
    icon: {
        fontSize: 36
    }
}

export default function FloatingScroller(props: FloatingScrollerProps) {
    const isOnScreen = useOnScreen(props.id);
    const isNextOnScreen = useOnScreen(props.nextScroll);
    const { scrollTo } = useScroll();
    const copy = useCopy();

    return <Stack direction="column" sx={[{display: isOnScreen && !isNextOnScreen ? "flex" : "none"}, styles.floating, styles.box]}>
        <Box>
            <IconButton sx={[styles.button, ]} onClick={() => {
                props.nextScroll === "top" ? window.scrollTo({top: 0, behavior: "smooth"}) : scrollTo(props.nextScroll);
            }}>
                { props.nextScroll === "top" ? <ExpandLess sx={styles.icon}/> : <ExpandMore sx={styles.icon}/>}
            </IconButton>
        </Box>

        <Typography sx={{color: props.color}}>{props.nextScroll === "top" ? copy.pages.scrollToTop : props.label}</Typography>
    </Stack>
};