import { List, ListItem, ListItemIcon, Stack, Typography } from "@mui/material";
import Markdown from "components/markdown/Markdown";
import Subtitle from "components/subtitle/Subtitle";
import { useCopy } from "data/copy";
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import { themeColors } from "features/theme/colors";
import { useIsMobile } from "hooks/mobile";

interface IntroTextProps {};

const styles = {
    main: {
        "& .MuiTypography-h2": {
            mt: 4,
        }
    },
    title: {
    },
    listItem: {
        fontWeight: "bold",
    }
}

export default function IntroText(props: IntroTextProps) {
    const copy = useCopy();
    const isMobile = useIsMobile();

    return <Stack direction="column" sx={styles.main}>
        <Markdown sx={{fontSize: isMobile ? 12 : 18}}>
            {copy.solutions.intro}
        </Markdown>
        <Subtitle>{copy.solutions.whatWeCanDo}</Subtitle>
        <List>
            {
                copy.solutions.types.map((type: string) => {
                    return <ListItem key={type}>
                        <ListItemIcon>
                            <EmojiObjectsOutlinedIcon htmlColor={themeColors.primary.contrastText}/>
                        </ListItemIcon>
                        <Typography sx={styles.listItem}>{type}</Typography>
                    </ListItem>
                })
            }
        </List>
    </Stack>
}