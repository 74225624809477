import { Button } from "@mui/material";
import { themeColors } from "features/theme/colors";
import { useScroll } from "hooks/scroll";
import { useNavigate } from "react-router-dom";

interface CoverButtonProps {
    label: string
    href: string
};

const styles = {
    button: {
        color: themeColors.primary.contrastText,
        borderColor: themeColors.primary.contrastText,
        minWidth: "200px",
        fontSize: 24,
        transition: "background 1s, color 1s",        
        "&:hover": {
            color: themeColors.primary.main,
            backgroundColor: themeColors.primary.contrastText,
            transition: "background 0.5s, color 0.5s",

        }
    }
}

export default function CoverButton(props: CoverButtonProps) {
    const { scrollTo } = useScroll();
    const navigate = useNavigate();

    return <Button
            onClick={() => {props.href.startsWith("#") ? scrollTo(props.href.replace("#", "")) : navigate(props.href)}}
            variant="outlined"
            sx={styles.button}
        >
        {props.label.toUpperCase()}
    </Button>
}