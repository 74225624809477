import { themeColors } from "features/theme/colors";
import MuiMarkdown, { getOverrides } from "mui-markdown";

interface PolicyProps {
    policy: string
}

export default function Policy(props: PolicyProps) {
    return <MuiMarkdown overrides={
        {
            ...getOverrides({}),
            a: {
                component: "a",
                props: {
                    style: {
                        color: themeColors.primary.contrastText
                    }
                }
            },
            p: {
                component: "p",
                props: {
                    style: {
                        paddingVertical: 2
                    }
                }
            }
        }
    }>{props.policy}</MuiMarkdown>
}