import { Rating, Stack, Typography } from "@mui/material"
import { useIsMobile } from "hooks/mobile"

interface Skill {
    name: string
    stars: number
}

interface SkillCategory {
    category: string
    skills: Array<Skill>
}

interface SkillsItemProps {
    skillCategory: SkillCategory
    noTitle?: boolean
}

const styles = {
    category: {
        fontWeight: "bold"
    },
    skill: {
        whiteSpace: "nowrap"
    },
    skillStack: {
        justifyContent: "space-between"
    }
}

export default function SkillsItem(props: SkillsItemProps) {
    const isMobile = useIsMobile();

    return <Stack direction="column" spacing={1}>
        {props.noTitle ? null : <Typography sx={styles.category}>{props.skillCategory.category}</Typography>}
        <Stack direction="column" spacing={0}>
            {
                props.skillCategory.skills.map((skill: Skill) => {
                    return <Stack direction="row" spacing={8} sx={styles.skillStack} key={skill.name}>
                        <Typography sx={[styles.skill, {fontSize: isMobile ? 12 : 18}]} key={skill.name}>{skill.name}</Typography>
                        <Rating value={skill.stars} readOnly size={isMobile ? "small" : "medium" }/>
                    </Stack>
                })
            }
        </Stack>
    </Stack>
}