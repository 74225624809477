import Markdown from "components/markdown/Markdown";
import Slide from "components/slide/Slide";
import { useCopy } from "data/copy";
import octopusImg from "assets/octopus.png";
import { Grid } from "@mui/material";
import Quote from "components/quote/Quote";
import { useIsMobile } from "hooks/mobile";

interface OctopusProps {}

const styles = {
    quote: {
        fontSize: 28,
        lineHeight: 1,
        fontFamily: "Agrandir Regular"
    }
}

export default function Octopus(props: OctopusProps) {
    const copy = useCopy();
    const isMobile = useIsMobile();

    return <Slide
        dark
        reverse
        id="octopus"
        title={copy.solutions.cases.octopus.title}
        img={octopusImg}
        nextScroll={{label: copy.solutions.approach.title, id: "approach"}}
    >
        <Grid container spacing={0} px={{xs: 1, lg: 8}}>
            <Grid item xs={12}>
                <Quote quoteSize={120} sx={styles.quote}>
                    {copy.solutions.cases.octopus.quote}
                </Quote>
            </Grid>
            <Grid item xs={12} px={{xs: 1, lg: 0}} pb={3}>
                <Markdown sx={{fontSize: isMobile ? 18 : 24}}>{copy.solutions.cases.octopus.description}</Markdown>
            </Grid>

        </Grid>
    </Slide>
}