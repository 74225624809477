import { Stack } from "@mui/material";
import Foodlose from "./Foodlose";
import Octopus from "./Octopus";

interface CasesProps {};

export default function Cases(props: CasesProps) {
    return <Stack direction="column" id="cases" width={"100%"}>
        <Foodlose />
        <Octopus />
    </Stack>
}