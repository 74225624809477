export const themeColors = {
  action: {
    active: '#6B7280',
    focus: 'rgba(55, 65, 81, 0.12)',
    hover: 'rgba(55, 65, 81, 0.04)',
    selected: 'rgba(55, 65, 81, 0.08)',
    disabledBackground: 'rgba(55, 65, 81, 0.12)',
    disabled: 'rgba(55, 65, 81, 0.26)'
  },
  background: {
    default: '#F9FAFC',
    paper: '#FFFFFF'
  },
  divider: '#E6E8F0',
  primary: {
    main: '#7c9676',
    light: '#96ab91',
    dark: '#566952',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#3a4937',
    light: '#616d5f',
    dark: '#283326',
    contrastText: '#FFFFFF'
  },
  success: {
    main: '#14B8A6',
    light: '#43C6B7',
    dark: '#0E8074',
    contrastText: '#FFFFFF'
  },
  info: {
    main: '#2196F3',
    light: '#64B6F7',
    dark: '#0B79D0',
    contrastText: '#FFFFFF'
  },
  warning: {
    main: '#FFB020',
    light: '#FFBF4C',
    dark: '#B27B16',
    contrastText: '#FFFFFF'
  },
  error: {
    main: '#D14343',
    light: '#DA6868',
    dark: '#922E2E',
    contrastText: '#FFFFFF'
  },
  text: {
    primary: '#121828',
    secondary: '#65748B',
    disabled: 'rgba(55, 65, 81, 0.48)'
  }
};

export function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) ?? ["0", "0", "0" ,"0"];

    return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 1)`;
  }