import Slide from "components/slide/Slide";
import { useCopy } from "data/copy";
import wordCloudImg from "assets/wordcloud.png";
import { Grid } from "@mui/material";
import Skills from "./Skills";

interface ToolsProps {};

const styles = {
    contentStack: {
        width: "50%"
    },
    imgBox: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "row"
    },
    img: {
        width: "80%",
    }
}

export default function Tools(props: ToolsProps) {
    const copy = useCopy();

    return <Slide id="tools" title={copy.consultancy.tools.title}>
        <Grid container spacing={4} direction="row" alignItems={"center"}>
            <Grid item xs={12} lg={6} pb={4}><Skills /></Grid>
            <Grid item xs={12} lg={6} sx={styles.imgBox} py={4}>
                <img src={wordCloudImg} alt="Skills and tools we specialize in for our consultancy" style={styles.img}/>
            </Grid>
        </Grid>
    </Slide>
}