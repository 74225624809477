import Slide from "components/slide/Slide";
import { useCopy } from "data/copy";
import Wrapper from "features/wrapper/components/Wrapper";
import contactImg from "assets/contact.jpg";
import { Fade, Grid, Typography } from "@mui/material";
import PersonalInfo from "features/consultancy/components/PersonalInfo";
import { themeColors } from "features/theme/colors";

interface ContactPageProps {};

const styles = {
    tagLine: {
        fontSize: 32,
        fontFamily: "Agrandir Regular"
    },
    infoBlock: {
        "& .MuiLink-root": {
            color: themeColors.primary.contrastText
        }
    }
}

export default function ContactPage(props: ContactPageProps) {
    const copy = useCopy();

    return <Wrapper>
        <Slide id="contact" dark title={copy.pages.contact} img={contactImg}>
            <Fade in={true} timeout={1500}>
                <Grid container spacing={8} px={8}>
                    <Grid item xs={12}>
                        <Typography sx={styles.tagLine}>{copy.contact.intro}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={styles.infoBlock}>
                        <PersonalInfo professional/>
                    </Grid>
                </Grid>
            </Fade>
        </Slide>
    </Wrapper>
};