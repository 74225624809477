import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "./mobile";

export function useScroll(id?: string) {
    const urlId = useLocation().hash.replace("#", "");
    const identifier = urlId ? urlId : id ?? "";
    const currentElement = document.getElementById(identifier);
    const [element, setElement] =  useState(currentElement);
    const isMobile = useIsMobile();

    function scrollTo(id: string) {
        //document.getElementById(id)?.scrollIntoView({behavior: "smooth"})
        const coordinate = document.getElementById(id)?.offsetTop;
        if (coordinate) {
            window.scrollTo({left: 0, top: coordinate - (isMobile ? 60 : 91), behavior: "smooth"})
        }
    };

    useLayoutEffect(() => {
        setElement(document.getElementById(identifier));
    }, [identifier]);

    useEffect(() => {
        setTimeout(() => {
            if (id) {
                const coordinate = element?.offsetTop;
                if (coordinate) {
                    window.scrollTo({left: 0, top: coordinate - (isMobile ? 60 : 91), behavior: "smooth"})
                }
        }}, 500)
    })

    return { scrollTo, element };
}