import Slide from "components/slide/Slide";
import { useCopy } from "data/copy"
import solutionsImg from "assets/solutions.jpg";
import Markdown from "components/markdown/Markdown";
import { Grid, Stack, Typography } from "@mui/material";
import Subtitle from "components/subtitle/Subtitle";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useIsMobile } from "hooks/mobile";

interface MeatProps {};

const styles = {
    text: {
        fontSize: 24
    },
    focusItem: {
        fontSize: 24
    }
}

export default function Meat(props: MeatProps) {
    const copy = useCopy();
    const isMobile = useIsMobile();

    return <Slide
        id="meat"
        title={copy.solutions.approach.meat.title}
        dark reverse
        img={solutionsImg}
        nextScroll={{label: copy.solutions.approach.serve.title, id: "serve"}}
    >
        <Grid container spacing={4} px={{xs: 2, lg: 8}} py={{xs: 2, lg: 0}}>
            <Grid item><Markdown sx={{fontSize: isMobile ? 18 : 24}}>{copy.solutions.approach.meat.description}</Markdown></Grid>
            <Grid item xs={12}><Subtitle>{copy.solutions.approach.focus}</Subtitle></Grid>
            <Grid item xs={12}>
                <Stack direction="column" spacing={2} pb={8}>
                    {
                        copy.solutions.approach.meat.focus.map((item: string) => (
                            <Stack direction="row" spacing={2} alignItems={"center"}>
                                <PlayArrowIcon />
                                <Typography sx={styles.focusItem}>{item}</Typography>
                            </Stack>
                        ))
                    }
                </Stack>
            </Grid>
        </Grid>
    </Slide>
}