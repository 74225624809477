import { Fade, Grid, Link, Stack } from "@mui/material";
import { useCopy } from "data/copy";
import CookieConsentMessage, { getCookieConsentValue } from "react-cookie-consent";
import { themeColors } from "features/theme/colors";
import ReactGA from "react-ga4";
import { useEffect, useState } from "react";

const GOOGLE_ANALYTICS_ID = "G-6753Q3T0HT";

interface CookieConsentProps {};

const styles = {
    message: {
        position: "fixed",
        backgroundColor: window.location.pathname === "/" ? "black" : themeColors.primary.main,
        display: "flex",
        flexDirection: "row"
    },
    grid: {
        "& .MuiLink-root": {
            textDecoration: "none",
            color: themeColors.primary.contrastText
        },
    },
    button: {
        paddingX: "20px",
        paddingY: "10px",
        color: themeColors.primary.main, 
        backgroundColor: themeColors.primary.contrastText
    },
    declineButton: {
        backgroundColor: themeColors.primary.dark,
        color: themeColors.primary.contrastText
    }
}

export default function CookieConsent(props: CookieConsentProps) {
    const copy = useCopy();
    const [accepted, setAccepted] = useState<boolean>(false);

    useEffect(() => {
        const consent = getCookieConsentValue("ga");
        if (consent) {
            ReactGA.send({ hitType: "pageview", page: window.location.pathname })
        }
    }, [accepted])

    return <>
        <CookieConsentMessage
            location="bottom"
            buttonText={copy.privacy.acceptCookies}
            cookieName="ga"
            style={styles.message as any}
            buttonStyle={styles.button}
            declineButtonStyle={{...styles.button, ...styles.declineButton}}
            expires={150}
            enableDeclineButton
            flipButtons
            onAccept={() => {ReactGA.initialize(GOOGLE_ANALYTICS_ID); setAccepted(true);}}
        >
            <Fade timeout={1000} in>
                <Grid container spacing={2} sx={styles.grid}>
                    
                    <Grid item xs={12}>
                        {copy.privacy.cookieConsent}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack direction={"row"} spacing={2}>
                            <Link href="/privacy">{copy.privacy.policyTitle}</Link>
                            <Link href="/cookies">{copy.privacy.cookiePolicyTitle}</Link>
                        </Stack>
                    </Grid>
                </Grid>
            </Fade>
        </CookieConsentMessage>
    </>
}