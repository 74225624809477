import { List, ListItem, Link} from "@mui/material";
import { useCopy } from "data/copy";
import Person from "@mui/icons-material/Person";
import Place from "@mui/icons-material/Place";
import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/LocalPhone';
import Apartment from '@mui/icons-material/Apartment';
import { LinkedIn } from "@mui/icons-material";

interface PersonalInfoProps {
    professional?: boolean
};

const styles = {
    icon: {
        mr: 2,
    },
    list: {
    },
    item: {
        whiteSpace: "nowrap",
        "& .MuiLink-root": {
            textDecoration: "none",
            alignItems: "center",
            display: "flex"
        }
    },

}

export default function PersonalInfo(props: PersonalInfoProps) {
    const copy = useCopy();

    return <List sx={styles.list}>
        {
            props.professional ? <>
                <ListItem sx={styles.item}><Apartment sx={styles.icon}/>{copy.info.company}</ListItem>
                <ListItem sx={styles.item}><Link href={`mailto:${copy.info.companyEmail}`}><Email sx={styles.icon}/>{copy.info.companyEmail}</Link></ListItem>
                <ListItem sx={styles.item}><Link href={`${copy.info.linkedInLinkAGITS}`} target="_blank"><LinkedIn sx={styles.icon}/>{copy.info.company}</Link></ListItem>
            </> : <>
                <ListItem sx={styles.item}><Person sx={styles.icon}/>{copy.info.name}</ListItem>
                <ListItem sx={styles.item}><Link href={`mailto:${copy.info.email}`}><Email sx={styles.icon}/>{copy.info.email}</Link></ListItem>
                <ListItem sx={styles.item}><Link href={`${copy.info.linkedInLink}`} target="_blank"><LinkedIn sx={styles.icon}/>{copy.info.name}</Link></ListItem>
            </>
        }
        <ListItem sx={styles.item}><Link href={`tel:${copy.info.phone}`}><Phone sx={styles.icon}/>{copy.info.phone}</Link></ListItem>
        <ListItem sx={styles.item}><Link href={`https://www.google.com/maps/search/?api=1&query=${copy.info.address.split(" ").join("+")}`} target="_blank"><Place sx={styles.icon}/>{copy.info.address}</Link></ListItem>
    </List>
}