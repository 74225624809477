import { Button } from "@mui/material";
import { themeColors } from "features/theme/colors";
import { useNavigate } from "react-router-dom";
import IRoute from "routes/route";

interface HeaderButtonProps {
    transparent?: boolean
    route: IRoute;
};

const styles = {
    transparent: {
        color: themeColors.primary.contrastText,
        fontSize: 18,
        transition: "background 0.5s, color 0.5s",
        "&:hover": {
            color: themeColors.primary.main,
            backgroundColor: themeColors.primary.contrastText
        }
    },
    button: {
        color: themeColors.primary.contrastText,
        fontSize: 18,
        transition: "background 0.5s, color 0.5s",
        "&:hover": {
            color: themeColors.primary.main,
            backgroundColor: themeColors.primary.contrastText
        }
    }
}

export default function HeaderButton(props: HeaderButtonProps) {
    const navigate = useNavigate();

    return <Button 
        onClick={() => navigate(props.route.path)}
        sx={[props.transparent ? styles.transparent : styles.button,]}
    >
            {props.route.name.toUpperCase()}
    </Button>
}