import { Box, Typography, hexToRgb } from "@mui/material"
import { themeColors } from "features/theme/colors";

interface TitleBoxProps {
    title: string
    dark?: boolean
};

const styles = {
    box: {
        pt: 2,
        pb: {xs: 2, lg: 4},
        pr: {xs: 2, lg: 4},
        pl: {xs: 2, lg: 4},
        mt: {xs: 2, lg: 4},
        ml: {xs: 0, lg: 4},
        mb: {xs: 2, lg: 4},
    },
    dark: {
        color: `linear-gradient(326deg, ${hexToRgb(themeColors.primary.main)} 0%, ${hexToRgb(themeColors.primary.light)} 60%)`,
    },
    light: {
        color: themeColors.primary.dark,
    },
    title: {
        fontSize: {xs: 24, lg: 48}
    }
};

export default function TitleBox(props: TitleBoxProps) {
    return <Box sx={[styles.box, props.dark ? styles.dark : styles.light]}>
        <Typography variant="h1" sx={styles.title}>{props.title.toUpperCase()}</Typography>
    </Box>
}