import Slide from "components/slide/Slide";
import { useCopy } from "data/copy"
import ApproachImg from "assets/approach.jpg";
import Markdown from "components/markdown/Markdown";
import Subtitle from "components/subtitle/Subtitle";
import { Grid, Button, Stack } from "@mui/material";
import { useScroll } from "hooks/scroll";
import { themeColors } from "features/theme/colors";
import { useIsMobile } from "hooks/mobile";

interface IntroProps {};

const styles = {
    intro: {
        fontSize: 24
    },
    buttons: {
        "& .MuiButton-root": {
            fontSize: 24
        }
    }
}
export default function Intro(props: IntroProps) {
    const copy = useCopy();
    const { scrollTo } = useScroll();
    const isMobile = useIsMobile();

    return <Slide
        id="intro"
        title={copy.solutions.approach.title}
        img={ApproachImg}
        nextScroll={{
            label: copy.solutions.approach.coffee.title,
            id: "coffee",
            color: themeColors.primary.contrastText
        }}
    >
        <Grid container spacing={4} px={{xs: 2, lg: 8}} py={{xs: 2, lg: 0}}>
            <Grid item xs={12}><Subtitle>{copy.solutions.approach.intro.tagline}</Subtitle></Grid>
            <Grid item xs={12}><Markdown sx={{fontSize: isMobile ? 18 : 24}}>{copy.solutions.approach.intro.text}</Markdown></Grid>
            <Grid item xs={12} pb={8}>
                <Stack direction="column" alignItems={"flex-start"} sx={styles.buttons}>

                    {
                        [
                            {
                                scroll: "coffee",
                                label: copy.solutions.approach.coffee.title
                            },
                            {
                                scroll: "appetizer",
                                label: copy.solutions.approach.appetizer.title
                            },
                            {
                                scroll: "meat",
                                label: copy.solutions.approach.meat.title
                            },
                            {
                                scroll: "serve",
                                label: copy.solutions.approach.serve.title
                            }
                        ].map((item) => <Button onClick={() => {scrollTo(item.scroll)}}>
                            {item.label.toUpperCase()}
                        </Button>)
                    }

                </Stack>
            </Grid>
        </Grid>

    </Slide>
}