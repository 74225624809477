import { Stack, Typography } from "@mui/material"
import IRoute from "routes/route";
import HeaderButton from "./HeaderButton";
import { useCopy } from "data/copy";
import { useNavigate } from "react-router-dom";
import logo from "assets/logoNoText.svg";
import { themeColors } from "features/theme/colors";
import { useEffect, useState } from "react";

interface HeaderProps {
    transparent?: boolean
    routes: IRoute[]
};

const styles = {
    title: {
        fontSize: 18,
        color: "white",
        fontWeight: "bold",
        py: 4,
        fontFamily: "Agrandir Wide",
        letterSpacing: "3px",
    },
    mainStack: {
        position: "fixed",
        width: "100%",
        zIndex: 2,
        justifyContent: "space-between",
        alignItems: "center",
    },
    buttonStack: {
        px: 4
    },
    logoStack: {
        alignItems: "center",
        cursor: "pointer",
    },
    transparent: {

    },
    filled: {
        backgroundColor: themeColors.primary.main
    }
};

export default function Header(props: HeaderProps) {
    const navigate = useNavigate();
    const copy = useCopy();
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    return <Stack direction="row" sx={[
            styles.mainStack,
            props.transparent && scrollPosition < 100 ? styles.transparent : styles.filled
        ]} id="header-stack">
        <Stack direction="row" sx={styles.logoStack} onClick={() => {navigate("/")}} spacing={2}>
            <img src={logo} width="60px" alt="AG IT Solutions logo"/>
            <Typography sx={styles.title}>{copy.header.title.toUpperCase()}</Typography>
        </Stack>
        <Stack direction="row" sx={styles.buttonStack} spacing={2}>
            {
                props.routes.map((route: IRoute) => <HeaderButton route={route} key={route.name} transparent={props.transparent}/>)
            }
        </Stack>
    </Stack>
}