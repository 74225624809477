import Wrapper from "features/wrapper/components/Wrapper";
import Header from "../components/header/Header";
import { useScroll } from "hooks/scroll";
import Cases from "../components/cases/Cases";
import Approach from "../components/approach/Approach";

interface SolutionsPageProps {};

export default function SolutionsPage(props: SolutionsPageProps) {
    useScroll("solutions");

    return <Wrapper>
        <Header />
        <Cases />
        <Approach />
    </Wrapper>
};