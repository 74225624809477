import { Box, Grid, Stack } from "@mui/material";
import { ReactNode } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useRoutes } from "routes/routes";
import MobileHeader from "./Header/MobileHeader";
import { useIsMobile } from "hooks/mobile";

interface WrapperProps {
    children: ReactNode | ReactNode[]
    transparent?: boolean
};

const styles = {
    main: {
        minHeight: "100vh"
    },
    stack: {
        alignItems: "center",
    },
    header: {
        zIndex: 2
    }
};


export default function Wrapper(props: WrapperProps) {
    const { tabs } = useRoutes();
    const isMobile = useIsMobile();

    return <Grid container direction="column" sx={styles.main}>
        <Grid item xs={12} sx={styles.header}>
            {
                isMobile ? 
                    <MobileHeader routes={tabs} transparent={props.transparent} /> : 
                    <Header routes={tabs} transparent={props.transparent}/>
            }
        </Grid>
        <Grid item xs={12}>
            <Box sx={{ height: !props.transparent ? {xs: "60px", lg: "91px"} : 0}}/>
        </Grid>
        <Grid item xs={12}>
            <Stack direction="column" sx={[styles.stack]}>
                {props.children}
            </Stack>
        </Grid>
        <Grid item xs={12}>
            <Footer/>
        </Grid>

    </Grid>
}