import Markdown from "components/markdown/Markdown";
import Slide from "components/slide/Slide"
import { useCopy } from "data/copy";
import foodloseImg from "assets/foodlose2.png";
import { Grid } from "@mui/material";
import Quote from "components/quote/Quote";
import { themeColors } from "features/theme/colors";
import { useIsMobile } from "hooks/mobile";

interface FoodloseProps {}

const styles = {
    quote: {
        fontSize: 28,
        lineHeight: 1,
        fontFamily: "Agrandir Regular"
    }
}

export default function Foodlose(props: FoodloseProps) {
    const copy = useCopy();
    const isMobile = useIsMobile();

    return <Slide
        id="foodlose"
        title={copy.solutions.cases.foodlose.title}
        img={foodloseImg}
        nextScroll={{
            label: copy.solutions.cases.octopus.short, 
            id: "octopus",
            color: themeColors.primary.contrastText
        }}
    >
        <Grid container spacing={0} px={{xs: 1, lg: 8}}>
            <Grid item xs={12}>
                <Quote quoteSize={120} sx={styles.quote}>
                    {copy.solutions.cases.foodlose.quote}
                </Quote>
            </Grid>
            <Grid item xs={12} px={{xs: 2, lg: 0}} pb={3}>
                <Markdown sx={{fontSize: isMobile ? 18 : 24}}>{copy.solutions.cases.foodlose.description}</Markdown>
            </Grid>
        </Grid>
    </Slide>
}