import { Collapse, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import Chip from "./Chip"
import { useState } from "react"
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { themeColors } from "features/theme/colors";
import { useCopy } from "data/copy";

interface ProjectProps {
    project: {
        role: string
        company: string
        details: string
        period: string
        tags: Array<{
            tag: string
        }>
    },
    index: number
}

const styles = {
    projectStack: {
        pb: 4,
        width: "100%"
    },
    titleStack: {
        justifyContent: "space-between",
        width: "100%"
    },
    projectTitle: {
        fontWeight: "bold",
        pt: {xs: 0, lg: 1},
        pb: {xs: 0, lg: 1}
    },
    projectDetails: {

    }
}

export default function Project(props: ProjectProps) {
    const copy = useCopy();
    const [hasClickedTooltip, setHasClickedTooltip] = useState(false);
    const [open, setOpen] = useState(false);

    return  <Stack direction="row" alignItems={"flex-start"}>
        <Tooltip 
            title={copy.consultancy.projects.moreInfo} 
            arrow 
            open={props.index === 0 && !hasClickedTooltip}
            placement="top-start"
        >
            <IconButton onClick={() => {setOpen(!open); setHasClickedTooltip(true)}} >
                {open ? <ExpandLess htmlColor={themeColors.primary.contrastText}/> : <ExpandMore htmlColor={themeColors.primary.contrastText}/>}
            </IconButton>
        </Tooltip>

        <Grid container direction="column" sx={styles.projectStack} spacing={2}>
            <Grid item xs={12}>
                <Grid container direction="row" sx={styles.titleStack} >
                    <Grid item xs={12} lg={6}><Typography sx={styles.projectTitle}>{props.project.role + " - " + props.project.company}</Typography></Grid>
                    <Grid item xs={12} lg={3}><Typography sx={styles.projectTitle}>{props.project.period}</Typography></Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Collapse in={open} timeout={500} >
                    <Typography sx={[styles.projectDetails]}>{props.project.details}</Typography>
                </Collapse>
            </Grid>
            
            <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                    {
                        props.project.tags.map((tag) => {
                            return <Grid item key={tag.tag}><Chip label={tag.tag} key={tag.tag} /></Grid>
                        })
                    } 
                </Grid>
            </Grid>
        </Grid>
    </Stack>

}