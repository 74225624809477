import { Box, Stack, SxProps } from "@mui/material"
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Markdown from "components/markdown/Markdown";

interface QuoteProps {
    children: string,
    quoteSize?: number
    sx?: SxProps
};

const styles = {
    stack: {}
}

export default function Quote(props: QuoteProps) {
    return <Stack direction="row" sx={styles.stack}>
        <FormatQuoteIcon sx={{fontSize: props.quoteSize ?? 24}}/>
        <Box pt={6}><Markdown sx={props.sx}>{props.children}</Markdown></Box>
    </Stack>
}