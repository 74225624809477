import React from 'react';

import { createBrowserRouter, useLocation, RouteObject, RouterProvider } from 'react-router-dom'
import { useRoutes } from 'routes/routes';


const ScrollToTop = (props: any) => {
    const location = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>
};


function MyRouter() {
    const { allRoutes: routeConfigs } = useRoutes();
    const router  = createBrowserRouter(routeConfigs.map((route: RouteObject) => {
        return {
            ...route, 
            element: <ScrollToTop>
                {route.element}
            </ScrollToTop>
        }
    }));
    return <RouterProvider router={router} />
};


export default MyRouter;