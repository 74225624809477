import { useCopy } from "data/copy";
import privacyImg from "assets/privacy.jpeg"
import Slide from "components/slide/Slide";
import Policy from "./Policy";

export interface CookiePolicyProps {};


export default function CookiePolicy(props: CookiePolicyProps) {
    const copy = useCopy();

    return <Slide title={copy.privacy.cookiePolicyTitle} dark id={"cookies"} img={privacyImg}>
        <Policy policy={copy.privacy.cookiePolicy} />
    </Slide>

}