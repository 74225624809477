import { useScroll } from 'hooks/scroll';
import { HomePageCover } from '../components';
import ConsultancyBlock from '../components/consultancy/ConsultancyBlock';
import SolutionsBlock from '../components/solutions/SolutionsBlock';
import Wrapper from 'features/wrapper/components/Wrapper';

interface HomePageProps {};


export default function HomePage(props: HomePageProps) {
  useScroll();

  return <Wrapper transparent>
      <HomePageCover/>
      <ConsultancyBlock />
      <SolutionsBlock />
  </Wrapper>
}