import { Box, Button, Grid } from "@mui/material";
import Slide from "components/slide/Slide";
import { useCopy } from "data/copy";
import consultancyImg from "assets/consultancy.jpeg";
import { useNavigate } from "react-router-dom";
import Markdown from "components/markdown/Markdown";
import SkillsItem from "features/consultancy/components/SkillsItem";
import { themeColors } from "features/theme/colors";
import { useIsMobile } from "hooks/mobile";
import Subtitle from "components/subtitle/Subtitle";

interface ConsultancyBlockProps {};

const styles = {
    mainStack: {
        width: "100%",
        height: "100%"
    },
    text: {
        width: "60%"
    },
    buttonBox: {
        display: "flex",
        justifyContent: {lg: "flex-end", xs: "center"},
        pl: {xs: 0, lg: 6},
        pb: {xs: 2, lg: 4}
    },
    button: {
        flexGrow: 0
    }
}
export default function ConsultancyBlock(props: ConsultancyBlockProps) {
    const copy = useCopy();
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    return <Slide
        title={copy.pages.consultancy}
        id={"consultancy"}
        img={consultancyImg}
        nextScroll={{label: copy.pages.solutions, id: "solutions", color: themeColors.primary.contrastText}}
    >
        <Grid container direction="column" sx={styles.mainStack} px={{xs: 2, lg: 8}} spacing={{xs: 4, lg: 2}}>
            <Grid item xs={12}>
                <Subtitle>{copy.pages.about}</Subtitle>

                <Markdown sx={{fontSize: isMobile ? 12 : 18}}>
                    {copy.home.consultancyIntro}
                </Markdown> 
            </Grid>

            <Grid item xs={12}>
                <Subtitle>{copy.home.consultancyIntroTheProfessional.title}</Subtitle>

                <Markdown sx={{fontSize: isMobile ? 12 : 18}}>
                    {copy.home.consultancyIntroTheProfessional.text}
                </Markdown> 
            </Grid>
            <Grid item xs={12}>
                <Box pb={{xs: 2, lg: 2}}><Subtitle>{copy.consultancy.tools.technical.domains.category}</Subtitle></Box>
                <SkillsItem skillCategory={copy.consultancy.tools.technical.domains} noTitle />
            </Grid>
            <Grid item xs={12}>  
                <Box sx={styles.buttonBox}>
                    <Button
                        onClick={() => navigate("/consultancy")}
                        sx={styles.button}
                        variant="outlined"
                    >
                        {copy.home.learnMore}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    </Slide>
}