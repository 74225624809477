import { ThemeProvider } from '@emotion/react';
import CookieConsent from 'components/cookieConsent/CookieConsent';
import theme from 'features/theme/theme';
import MyRouter from 'routes/router';

function App() {
  return <ThemeProvider theme={theme}>
    <MyRouter />
    <CookieConsent />
  </ThemeProvider>;
}

export default App;
