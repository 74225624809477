import { Grid } from "@mui/material";
import { useCopy } from "data/copy";
import SkillsItem from "./SkillsItem";

interface SkillsProps {};

export default function Skills(props: SkillsProps) {
    const copy = useCopy();

    return <Grid container spacing={2} px={{xs: 1, lg: 8}}>
        <Grid item xs={12} lg={12}>
            <SkillsItem skillCategory={copy.consultancy.tools.technical.data} />
        </Grid>
        <Grid item xs={12} lg={12}>
            <SkillsItem skillCategory={copy.consultancy.tools.technical.programming} />
        </Grid>
        <Grid item xs={12} lg={12}>
            <SkillsItem skillCategory={copy.consultancy.tools.technical.domains} />
        </Grid>
        <Grid item xs={12} lg={12}>
            <SkillsItem skillCategory={copy.consultancy.tools.technical.others} />
        </Grid>
    </Grid>
}