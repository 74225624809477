import Slide from "components/slide/Slide";
import { useCopy } from "data/copy";
import Wrapper from "features/wrapper/components/Wrapper";
import aboutPhoto from "assets/about.jpeg";
import { Button, Fade, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useScroll } from "hooks/scroll";
import Markdown from "components/markdown/Markdown";
import Subtitle from "components/subtitle/Subtitle";

interface AboutPageProps {};

const styles = {
    main: {
        justifyContent: "space-between",
        height: "100%"
    },
    contentBox: {
        height: "100%",
        width: "100%"
    },
    buttonBox: {
        mt: {xs: 2, lg: 8},
        justifyContent: "space-evenly",
        pb: {xs: 2, lg: 4}
    },
    photoBox: {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    photo: {
        width: "100%",
        borderRadius: 10
    },
    button: {
        minWidth: "200px",
        fontWeight: "bold"
    },
    buttonItem: {
        justifyContent: "center",
        display: "flex"
    }
}
export default function AboutPage(props: AboutPageProps) {
    const copy = useCopy();
    const navigate = useNavigate();

    useScroll("about");

    return <Wrapper>
        <Slide title={copy.pages.about} id={"about"}>
            <Fade in={true} timeout={1000}>
                <Grid
                    direction={{xs: "column-reverse", md: "row"}}
                    container
                    sx={styles.main}
                    px={{xs: 2, lg: 8}}
                    alignItems="flex-start"
                    spacing={{xs: 4, lg: 2}}
                >
                    <Grid item sx={styles.contentBox} xs={12} lg={7}>
                        <Subtitle>{copy.home.consultancyIntroTheProfessional.title}</Subtitle>
                        <Markdown sx={{ fontSize: 18, paddingBottom: "20px" }}>
                            {copy.about.intro[0]}
                        </Markdown>

                        <Subtitle>{copy.pages.solutions}</Subtitle>

                        <Markdown sx={{ fontSize: 18 }}>
                            {copy.about.intro[1]}
                        </Markdown>
                        <Grid container direction="row" sx={styles.buttonBox} spacing={2}>
                            <Grid item xs={12} lg={6} sx={styles.buttonItem}>
                                <Button variant="outlined" onClick={() => navigate("/consultancy")} sx={styles.button}>
                                    {copy.pages.consultancy}
                                </Button>
                            </Grid>
                            <Grid item xs={12} lg={6} sx={styles.buttonItem}>
                                <Button variant="outlined" onClick={() => navigate("/solutions")} sx={styles.button}>
                                    {copy.pages.solutions}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={styles.photoBox} xs={12} lg={3} >
                        <img src={aboutPhoto} alt="Our consultant Aron Geerts" style={styles.photo}/>
                    </Grid>
                </Grid>
            </Fade>
        </Slide>
    </Wrapper>
};