const tags = {
    aws: {
        color: "#FF9900",
        tag: "AWS Cloud"
    },
    teamLead: {
        color: "#222222",
        tag: "Team lead"
    },
    dataEngineering: {
        color: "#000088",
        tag: "Data Engineering"
    },
    softwareEngineering: {
        color: "#880000",
        tag: "Software Engineering"
    },
    devops: {
        color: "#888888",
        tag: "DevOps"
    }
}

export const copy = {
    header: {
        title: "AG IT-Solutions"
    },
    info: {
        title: "Personal information",
        name: "Aron Geerts",
        company: "AG IT-Solutions",
        vat: "BE",
        address: "2980 Zoersel, Antwerp",
        email: "aron.geerts@agits.be",
        companyEmail: "info@agits.be",
        phone: "+324 79 80 64 38",
        linkedInLink: "https://www.linkedin.com/in/aron-geerts-925b1072/",
        linkedInLinkAGITS: "https://www.linkedin.com/company/agits-ag-it-solutions"
    },
    pages: {
        home: "Home",
        about: "About",
        consultancy: "Consultancy",
        solutions: "Solutions",
        contact: "Contact",
        scrollToTop: "To top"
    },
    home: {
        header: `Need an IT-professional?`,
        intro: `AG IT Solutions is your go-to partner for consultancy services and custom business software. 
Let’s see what we can do to meet your needs!`,
        consultancyIntro: `
AG IT-Solutions provides consultancy services in various IT domains. The founder, Aron Geerts, works as a **freelance IT consultant** and offers services in both architecture and engineering positions.
`,
        consultancyIntroTheProfessional: {
            title: "The Professional",
            text: `
Aron is an IT-professional, with **over 5 years of experience** working in various companies and IT-environments.
In these projects, Aron has developed a wide range of knowledge on **software development**, **design** and **project management**. 

He uses and further develops these skills as a **freelance consultant**, 
specialized in **data engineering** and the **AWS cloud**. As a **certified AWS Solutions Architect Professional**, he is able to fill both **architectural and engineering roles** in various technology stacks.
        
`},
        learnMore: "Learn more"
    },
    about: {
        intro: [`AG IT-Solutions is a one-man company, founded by Aron Geerts. Aron is an IT-professional, with **over 5 years of experience** working in various companies and IT-environments.
In these projects, Aron has developed a wide range of knowledge on **software development**, **design** and **project management**. He uses and further develops these skills as a **freelance consultant**, 
specialized in **data engineering** and the **AWS cloud**. As a **certified AWS Solutions Architect Professional**, he is able to fill both **architectural and engineering roles** in various technology stacks.
`,
`
Next to his occupation as a freelance consultant, he develops **custom business applications** for **small and medium-sized businesses**, who are in need of tailored software. Often, these companies operate in very specific domains, with very specific problems and needs.
Therefore, they look for professionals who can develop **tailored applications**, without the large overhead costs and communication struggles of big IT companies.

Working at smaller scale, AG IT-Solutions values the **close contact** with its clients. By keeping you close, we aim to achieve maximum **flexibility**, **transparency** and **customer experience**.
These values push us to always focus on **close collaboration** with direct communication lines, leading to **better understanding** and **solving the needs** of our clients.

Are you curious to see what we can do for you? Deepdive into our focus area's and expertise using the buttons below!
`]
    },
    consultancy: {
        info: {
            title: "Personal information",
            intro: `
Aron is a motivated, technically skilled **software engineer** with a broad interest and understanding
of the IT landscape. He mainly worked in **data & analytics** departments, but has experience
on tasks ranging from **front-end development** to **platform engineering** and **DevOps**. His strong
technical and **communication skills** have put him into **team-leading roles** early on in his career, which
also helped him to quickly develop these assets further. Aron believes in **solid software design principles**, thinking before doing and ’automation first’ development.\n

As the **founder of AG IT-Solutions**, Aron develops software on
demand for small businesses. He does this by himself as it helps him to learn and develop technical
as well as non-technicall skills.\n

Next to work, Aron is a football enthusiast, as he captains his team in Belgium’s 1st regional division.
He also likes spending time with friends & family, travelling and good food.
            `,
            education: {
                title: "Education",
                items: [
                    {
                        title: "Bachelor of Science",
                        subtitle: "Major: Computer Science - Business management",
                        institute: "KU Leuven, Department of Engineering",
                        period: "2013-2016"
                    },
                    {
                        title: "Master of Computer Science",
                        subtitle: "Major: Artificial Intelligence",
                        institute: "KU Leuven, Department of Engineering",
                        period: "2016-2018"
                    },
                    {
                        title: "Master thesis",
                        subtitle: "The automatic analysis of football players' playing styles",
                        institute: "KU Leuven, Department of Engineering",
                        period: "2016-2018"
                    }
                ],
            },
            certification: {
                title: "Certification",
                items: [
                    {
                        topic: "AWS",
                        certificate: "AWS Solutions Architect Associate",
                        year: "2018"
                    },
                    {
                        topic: "AWS",
                        certificate: "AWS Solutions Architect Professional",
                        year: "2021"
                    },
                    {
                        topic: "Agile SAFe",
                        certificate: "Certified SAFe Agilist",
                        year: "2022"
                    }
                ]
            },                
        },
        projects: {
            title: "Projects & experience",
            moreInfo: "Click for more",
            projects: [
                {
                    role: "Big Data Consultant",
                    tags: [tags.aws, tags.dataEngineering],
                    company: "Ordina",
                    period: "Aug 2018 - Oct 2022",
                    details: `The first four years of his career, Aron spent as a Big Data Consultant at Ordina, on their payroll.
                    In this role, he participated in various projects, majorly focussed on data engineering, software engineering and AWS Cloud implementations.`
                },
                {
                    role: "Competence Center Lead Big Data, Python & AWS",
                    tags: [tags.aws, tags.dataEngineering, tags.teamLead],
                    company: "Ordina",
                    period: "March 2019 - Oct 2022",
                    details: `During his time at Ordina, Aron took the secondary role of "Competence Center Lead Big Data, Python & AWS".
                    His main responsibility was to guide his colleagues to improve their skills in these fields, as well as connecting them and organizing workshops to do so.
                    He also took ownership of Ordina's internal education program for new joiners, focussing on Python and AWS specifically.`
                },
                {
                    role: "Big data & cloud engineer",
                    tags: [tags.aws, tags.dataEngineering],
                    company: "Nike (through Ordina)",
                    period: "Oct 2018 - Feb 2020",
                    details: `Successfully built a platform to load logistics data from different sources into the enterprise data
                    lake. The platform was generalized and after successful implementation in Belgium, expanded to
                    onboard data sources in the US and South America. Aron was hired as a junior AWS consultant at
                    the beginning of this project and was responsible for development of the platform. Later he became
                    the team lead and also assisted the global implementation in the US.`
                },
                {
                    role: "Data / Software engineer",
                    tags: [tags.aws, tags.dataEngineering, tags.softwareEngineering, tags.devops, tags.teamLead],
                    company: "Nike (through Ordina)",
                    period: "Feb 2020- Oct 2021",
                    details: `Successfully created a complete PaaS solution for other teams at Nike to quickly bring new data
                    solutions to production and shorten the time-to-market. Aron became the engineering lead when
                    this project started and acted as a full-stack engineer. He picked up tasks that were related to
                    infrastructure, as well as data engineering, DevOps and front-end.`
                },
                {
                    role: "Platform & DevOps Engineer",
                    tags: [tags.aws, tags.dataEngineering, tags.devops, tags.teamLead],
                    company: "Brussels Airport Company (through Ordina)",
                    period: "Oct 2021 - Oct 2022",
                    details: `Build and maintain the central data platform of the airport. In his role as a platform engineer,
                    Aron is mainly responsible for development and maintenance of the data platform. This includes
                    infrastructure setup, platform services and integrations with on-premise or other cloud systems.
                    Next to that, he is also responsible for implementing best practices in the other engineering teams
                    at BAC. After 4 months, Aron also took the role of Technical Lead and Project Manager. This
                    helped him in developing more non-technical skills.`
                },
                {
                    role: "Team Lead Data & Software Engineering",
                    tags: [tags.aws, tags.dataEngineering, tags.softwareEngineering, tags.teamLead],
                    company: "DPG Media (Freelance)",
                    period: "Oct 2022 - Present",
                    details: `In this project, Aron leads a team of four data engineers in the Digital Advertising space. 
                    He is responsible for a software product the team is building for internal use. In his role, he is responsible for the solution architecture
                    of the product, as well as implementing best practices and supporting the growth of the team. `
                }
            ]
        },
        tools: {
            title: "Tools & Fields",
            technical: {
                programming: {
                    category: "Programming Languages",
                    skills: [
                        {
                            name: "Python",
                            stars: 5
                        },
                        {
                            name: "TypeScript",
                            stars: 4
                        },
                        {
                            name: "GoLang",
                            stars: 4
                        },
                        {
                            name: "Java",
                            stars: 3
                        },
                        
                    ]
                },
                data: {
                    category: "Data",
                    skills: [
                        {
                            name: "Spark",
                            stars: 5
                        },
                        {
                            name: "Airflow",
                            stars: 5
                        },
                        {
                            name: "Kafka",
                            stars: 4
                        },
                        {
                            name: "Snowflake",
                            stars: 4
                        },
                        {
                            name: "SQL",
                            stars: 4
                        },
                        
                    ]
                },
                domains: {
                    category: "Domains",
                    skills: [
                        {
                            name: "Data engineering",
                            stars: 5
                        },
                        {
                            name: "Software architecture",
                            stars: 5
                        },
                        {
                            name: "Software engineering - Backend",
                            stars: 5
                        },
                        {
                            name: "Software engineering - Frontend",
                            stars: 4
                        },
                        {
                            name: "DevOps",
                            stars: 4
                        },
                    ]
                },
                others: {
                    category: "Other technologies",
                    skills: [
                        {
                            name: "AWS",
                            stars: 5
                        },
                        {
                            name: "Terraform",
                            stars: 5
                        },
                        {
                            name: "Docker & Kubernetes",
                            stars: 5
                        },
                        {
                            name: "ReactJS",
                            stars: 4
                        },
                        {
                            name: "Jenkins",
                            stars: 3
                        },
                    ]
                }
            }
        },
        availability: {
            title: "Availability"
        }
    },
    solutions: {
        intro: `
AG IT-Solutions creates **business software** on demand, tailored to the needs of our customers. With our **personal approach**, we always aim for **flexible solutions** for your problems. We provide business software in **various domains** and make sure you don't have to worry about the complexity of your IT systems.
`,
        whatWeCanDo: "What we can do for you",
        types: [
            "Web applications",
            "Mobile applications",
            "Business software",
            "Automation",
            "Webhosting"
        ],
        discoverMore: "Discover more",
        customerCases: "Customer cases",
        ourApproach: "Our approach",
        cases: {
            foodlose: {
                title: "Case: Web application for Foodlose",
                description: `
On their **web platform**, developed by AG IT-Solutions, Foodlose shares healthy and delicious recipes. Via an admin interface, they can login to the application and instantly add or modify their recipe collection.
The public website was completely styled to the taste of Charlotte, the founder of Foodlose, which allowed her to unify her unique style across her social media and her website. 

Because of the serverless **cloud solution** of AG IT-Solutions, the web platform is up and running at a **low cost**, which is ideal for companies who don't want to spend hundreds or even thousands of Euro's per year on their digital footprint.

Want to **see more**? Checkout <a href="https://foodlose.be" target="_blank">Foodlose's beautiful website</a>!
`,
            quote: `
From the first intake to the final version, Aron listened carefully to our needs and goals. His personal approach and interesting suggestions made us feel comfortable throughout the process.            

-- Charlotte, founder of Foodlose
`

            },
            octopus: {
                title: "Case: Automating 3D drawings for Plan BV",
                description: `
Plan BV is a **land surveying company** based in Herentals. As they make measurements on the field, they eventually deliver very precise plans of the infrastructure they measure. To create a correct and accurate overview of the location, complex mathematical transformations have to be applied to the measured data.

As these requirements are very specific to the domain of land surveying, Plan were in need of tailored business software. AG IT-Solutions developed a **web application**, Octopus, which **automates the complete process** from uploading measurement data to generating drawings. The platform offers room for customization by the employees of Plan, so that they can differentiate the drawing behavior for different customers.

The resulting drawings are created in the familiar AutoCAD-format, so that they **integrate with their existing software** for final editing if needed.
`,
                short: "Automation at Plan BV",
                quote: `
Octopus is a vital tool in our daily operations to turn our digital measurement data into AutoCAD drawings

-- Alain, Co-founder of Plan BV
`
            }
        },
        approach: {
            title: "Our approach",
            focus: "Our focus",
            intro: {
                tagline: "Curious to know how we work?",
                text: `
We believe a structured approach gives the best results. Therefore, we follow **four crucial steps** to meet our customers' needs. 

Checkout more below to see what we bring to the table!
`
            },
            coffee: {
                title: "1. The coffee",
                description: `
In the first phase, we focus on **getting to know our customers**. We try to get an **insight** in what they do, what **problems they face** and how we can help them in the best way possible. 

In a couple of conversations, we take note on the **struggles and key areas** of our clients. Based on this, we work together on creating a **project scope**. This means that we define what we may expect from each other. Ofcourse, this is a **flexible proposal**, as requirements may change over time, but this gives a good start to dive deeper into the problem.

Even though coffee often costs about €2.50, having a coffee with us is **always free of charge**. Don't hesitate to reach out to us, to see if we can help you.
`,
                focus: [
                    "Get to know each other",
                    "Identify your needs",
                    "Create a project scope"
                ]
            },
            appetizer: {
                title: "2. The appetizer",
                description: `
Once the project scope is clear, we enter the **design phase**. In this phase, we provide **mock-ups, designs and schema's** that go into more detail on the solution. Making things visible and tangible, helps to understand each other better and to make more **detailed agreements**. It also serves to eliminate any misunderstandings about project scoping.

Creating good designs is always done in **iterations**. We start from high-level mock-ups and go deeper and deeper into detail, until we reach a **final definition of the finished product**. It is important to get this right, as it saves a lot of time when actually building the application.

Once your design is complete, we are all set to bring your project to life!
`,
                focus: [
                    "Design the solution",
                    "Dive into details",
                    "Agree on the scope"
                ]
            },
            meat: {
                title: "3. The meat",
                description: `
Now that we have a better understanding of what we will build, we can actually **start the development**. Usually, this is the longest phase in the project. 

To make you stay up-to-date with the progress, we provide regular **client demo's**. This gives our clients the opportunity to **give feedback and ask questions**. It also helps to make sure expectations and reality stay close to each other. 

Seeing the product work often gives new insights or ideas, which we want to include early in the process.
`,
                focus: [
                    "Build the product",
                    "Get feedback",
                    "Report progress"
                ]

            },
            serve: {
                title: "4. Ready to serve",
                description: `
When the development phase reaches its end, we enter the final step of the collaboration. Here, we **assist in the implementation** into our clients' operations and help them with the actual **usage of the product**. We also provide documentation on how to use the software, so that you always have a reference at hand. 

Of course we always aim for a **smooth go-live**. However, it is possible that issues start to come up once the software is actually getting used at scale. Therefore we make sure that we are closeby to resolve them as fast as possible. 

Even after our collaboration, we are always reachable for **changes, extensions and improvements** if necessary. Each of these, we typically treat as separate mini-projects. AG IT-Solutions also **manages hosting** of your applications, so that you don't need to worry about any subscriptions or IT infrastructure.
`,
                focus: [
                    "Ensure a smooth go-live",
                    "Provide documentation",
                    "Help and assist"
                ]
            }
        }
    },
    contact: {
        intro: "Curious to discover what we can do for you? Reach out!"
    },
    privacy: {
        policyTitle: "Privacy Policy",
        privacyPolicy: `
Privacy Policy for AG IT-Solutions Website

Last Updated: 23/08/2023

1. Introduction

    Welcome to AG IT-Solutions. We are committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal data when you visit our website ("https://agits.be").

2. Information We Collect

    - Personal Information: We may collect personal information such as your name and email when you fill out the contact form. AG IT-Solutions does not store your personal information for any other purposes.
    - Usage Data: We automatically collect certain information about your device, browsing actions, and patterns. This may include your IP address, browser type, referring/exit pages.

3. How We Use Your Information

    - We use your personal information to provide services and be able to contact you when you reach out to us.
    - We may use usage data for improving our website and gaining insights on our website traffic.

4. Cookies and Similar Technologies

    - We use cookies that are required to use Google Analytics for tracking website usage and pattterns.

5. Sharing Your Information

    - We may share your website browsing information with with Google Analytics]. AG IT-Solutions will not share any of your personal data.

6. Your Rights

    - You have the right to be forgotten by AG IT-Solutions.
    - To exercise your rights, please send an email to info@agits.be.

7. Data Security

    - We do not store your personal information to avoid data security issues.

8. Changes to This Privacy Policy

    - We may update this Privacy Policy. You can track changes to our privacy policy by monitoring our website.

9. Contact Us

    - If you have any questions or concerns about this Privacy Policy, please send an email to info@agits.be.
        `,
        cookiePolicyTitle: "Cookie policy",
        cookiePolicy: `
Cookie Policy for AG IT-Solutions Website

Last Updated: [Date]

1. What Are Cookies

    Cookies are small pieces of data stored on your device (computer or mobile device) when you visit websites. They are widely used to make websites work or improve their performance, as well as to provide information to website owners.

2. How We Use Cookies

    We use cookies for analyzing website traffic. These cookies are analytical cookies.

3. Third-Party Cookies

    We also use third-party cookies provided by Google Analytics for tracking website usage. These third parties may use cookies to collect information about your online activities across this website.

4. Your Cookie Choices

    You can control and manage cookies in your browser settings. 

    You have the ability to manage cookies and other tracking technologies through your browser settings. Below are instructions for popular browsers:

    - **Google Chrome:**
        1. Click on the three-dot menu icon in the top-right corner.
        2. Select "Settings."
        3. Scroll down and click on "Privacy and security" in the left sidebar.
        4. Click on "Cookies and other site data."
        5. You can block or delete cookies by adjusting the settings here.

    - **Mozilla Firefox:**
        1. Click on the three-line menu icon in the top-right corner.
        2. Select "Options."
        3. Click on "Privacy & Security" in the left sidebar.
        4. Under "Cookies and Site Data," you can manage your cookie settings.

    - **Microsoft Edge:**
        1. Click on the three-dot menu icon in the top-right corner.
        2. Select "Settings."
        3. Scroll down and click on "Privacy, search, and services" in the left sidebar.
        4. Under "Cookies and other site data," you can manage cookie settings.

    - **Safari:**
        1. Click on "Safari" in the top-left corner and select "Preferences."
        2. Go to the "Privacy" tab.
        3. You can manage cookie settings using the options here.

    Please note that adjusting cookie settings may impact your experience on our website and other websites you visit. For more information about managing cookies, you can also visit www.allaboutcookies.org.

    Remember, by adjusting your browser settings to block or delete cookies, you may affect the functionality of our website and other websites you visit. It's important to strike a balance between your privacy preferences and the usability of online services.
        
5. Changes to This Cookie Policy

    We may update this Cookie Policy. You can track changes to our privacy policy by monitoring our website.

6. Contact Us

    If you have any questions or concerns about this Cookie Policy, please contact info@agits.be.
`,
        cookieConsent: "This website uses cookies for analytics purposes. More information is available in our privacy and cookie policy.",
        acceptCookies: "Accept",
        declineCookies: "Decline"

    }
};

export function useCopy() {
    return copy;
};