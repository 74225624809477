import Cover from "components/cover/Cover";
import homeCoverPhoto from "assets/homeCover.jpg";
import logo from "assets/logoWhite.svg";
import { Fade, Grid, Stack, Typography } from "@mui/material";
import { themeColors } from "features/theme/colors";
import { useCopy } from "data/copy";
import CoverButton from "./CoverButton";
import { FADE_TIMEOUT } from "../../config";
import { useIsMobile } from "hooks/mobile";

interface HomePageCoverProps {};

const styles = {
    box: {
        minHeight: "101vh",
    },
    coverStack: {
        justifyContent: "space-evenly",
        width: "100%",
        alignItems: "center",
        height: "100%",
        p: {xs: 2, lg: 8}
    },
    header: {
        fontSize: 36,
        fontWeight: "bold",
        fontFamily: "Agrandir Grand Heavy",
    },
    coverText: {
        color: themeColors.primary.contrastText,
        fontSize: 30,
        textAlign: "center",
        whiteSpace: "pre-wrap",

    },
    coverTextStack: {
        maxWidth: "100%",
    },
    buttonStack: {
        justifyContent: "space-evenly",
        marginY: 6

    }
}
export default function HomePageCover(props: HomePageCoverProps) {
    const copy = useCopy();
    const isMobile = useIsMobile();

    return <Cover img={homeCoverPhoto} sx={{filter: "grayscale(100%)"}}>
        <Fade timeout={FADE_TIMEOUT} in={true}>
            <Grid container direction="row" sx={styles.coverStack} >
                <Grid item xs={12} lg={4} justifyContent={"center"} display={"flex"}><img src={logo} alt="AG IT Solutions logo" style={{width: "100%"}}/></Grid>
                <Grid item xs={12} lg={8}> 
                    <Grid container>
                        <Grid item xs={12}>
                            <Stack direction="column" sx={styles.coverTextStack}>
                                <Typography sx={[styles.coverText, styles.header, {fontSize: isMobile ? 24 : 30, pb: 2}]}>{copy.home.header.toUpperCase()}</Typography>
                                <Typography sx={[styles.coverText, {fontSize: isMobile ? 18 : 30, px: {xs: 0, lg: 4}}]}>{copy.home.intro}</Typography>
                                <Grid container direction="row" sx={styles.buttonStack} spacing={2} justifyContent={"center"}>
                                    <Grid item xs={12} lg={4} justifyContent={"center"} display={"flex"}><CoverButton label={copy.pages.consultancy} href={"#consultancy"} /></Grid>
                                    <Grid item xs={12} lg={4} justifyContent={"center"} display={"flex"}><CoverButton label={copy.pages.solutions} href="#solutions" /></Grid>
                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fade>
    </Cover>
}