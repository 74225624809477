import MuiMarkdown, { getOverrides } from "mui-markdown"

interface MarkdownProps {
    children: string
    overrides?: any
    sx?: any
};

export default function Markdown(props: MarkdownProps) {
    return <MuiMarkdown overrides={{
        ...getOverrides({}),
        p: {
            component: 'p',
            props: {
                style: props.sx
            }
        },
        ...(props.overrides ?? {}),
    }}>
        {props.children}
    </MuiMarkdown>
}