import { Grid, Link, Stack } from "@mui/material";
import PersonalInfo from "features/consultancy/components/PersonalInfo";
import { themeColors } from "features/theme/colors";
import IRoute from "routes/route";
import { useRoutes } from "routes/routes";

interface FooterProps {};

const styles = {
    container: {
        justifyContent: "space-between",
        backgroundColor: themeColors.secondary.main,
        py: {xs: 4, lg: 4},
        px: {xs: 4, lg: 12},
        position: "",
        mt: 0,
        "& .MuiLink-root": {
            color: "white",
            textDecoration: "none"
        },
    },
    links: {

    },
    link: {
        color: "white"
    },
    contact: {
        color: "white",

        "& .MuiListItem-root": {
            px: 0,
            py: 0
        },
        "& .MuiList-root": {
            py: 0
        }
    }
}
export default function Footer(props: FooterProps) {
    const { tabs, pages } = useRoutes();


    return <Grid container sx={styles.container} spacing={8}>
        <Grid  sx={styles.links} item xs={12} lg={4}>
            <Stack direction={"column"}>
                {
                    tabs.map((route: IRoute) => <Link key={route.name} href={window.location.origin + route.path} sx={styles.link}>{route.name}</Link>)
                }
            </Stack>
        </Grid>
        <Grid sx={styles.links} item xs={12} lg={4}>
            <Stack direction={"column"}>
                {
                    pages.map((route: IRoute) => <Link key={route.name} href={window.location.origin + route.path} sx={styles.link}>{route.name}</Link>)
                }
            </Stack>
        </Grid>
        <Grid sx={styles.contact} item xs={12} lg={4}>
            <PersonalInfo professional/>
        </Grid>
    </Grid>
}