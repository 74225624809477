import { Fade, Grid } from "@mui/material";
import Slide from "components/slide/Slide";
import { useCopy } from "data/copy";
import profilePicture from "assets/about.jpeg";
import Education from "./Education";
import Certification from "./Certification";
import PersonalInfo from "./PersonalInfo";
import Markdown from "components/markdown/Markdown";

interface InfoProps {};

const styles = {
    mainStack: {
    },
    infoStack: {
        justifyContent: "flex-start",
        alignItems: "center",
        mb: 8
    },
    infoList: {

    },
    textStack: {
        justifyContent: "space-between",
    },
    picture: {
        width: "100%"
    },
    pictureBox: {
        height: "100%"
    }
}

export default function Info(props: InfoProps) {
    const copy = useCopy();

    return <Slide 
        id="info"
        title={copy.consultancy.info.title}
        nextScroll={{label: copy.consultancy.projects.title, id: "projects"}}
    >
        <Fade in={true} timeout={1000}>
            <Grid container direction="row" sx={styles.infoStack} spacing={{xs: 4, lg: 8}} px={{xs: 1, lg: 8}}>
                <Grid item sx={styles.pictureBox} xs={12} md={2}>
                    <img src={profilePicture} alt="Our consultant Aron Geerts" style={styles.picture} />
                </Grid>
                <Grid item xs={12} md={10} lg={7}>
                    <Markdown>
                        {copy.consultancy.info.intro}
                    </Markdown>
                </Grid>
                <Grid item xs={12} md={12} lg={3}>
                    <PersonalInfo />
                </Grid>
                <Grid item xs={12} >
                    <Grid container direction="row" spacing={8}>
                        <Grid item xs={12} lg={6}><Education /></Grid>
                        <Grid item xs={12} lg={6}><Certification /></Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Fade>
    </Slide>
}