import { Box, Grid } from "@mui/material";
import { hexToRgb, themeColors } from "features/theme/colors";
import { ReactElement } from "react";
import TitleBox from "./TitleBox";
import FloatingScroller from "features/floatingScroller/FloatingScroller";

interface SlideProps {
    title: string
    children?: ReactElement | ReactElement[]
    dark?: boolean
    reverse?: boolean
    id: string,
    img?: string,
    nextScroll?: {
        label: string
        id: string,
        color?: string
    },
};

const makeStyles = (img?: string) => ({
    container: {
        minHeight: "101vh",
        width: "100%",
    },
    dark: {
        background: `linear-gradient(326deg, ${hexToRgb(themeColors.primary.main)} 0%, ${hexToRgb(themeColors.primary.light)} 60%)`,
        color: themeColors.primary.contrastText
    },
    light: {
        color: themeColors.primary.main,
        background: themeColors.primary.contrastText
    },
    contentBox: {
        py: {xs: 0, lg: 2},
        px: {xs: 0, lg: 4},
        width: img ? "60%" : "100%",
        height: "100%",
        alignItems: "stretch"
    },
    img: {
        minHeight: {xs: "400px", lg: "100vh"},
        background: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    titleBoxContainer: {
        display: "flex",
        width: "100%",
    },
    nextScroll: {
    }
});

export default function Slide(props: SlideProps) {
    const styles = makeStyles(props.img);

    return <Grid
        container
        direction={props.reverse ? "row-reverse": "row"}
        sx={[styles.container, props.dark ? styles.dark : styles.light]} id={props.id}
    >
        <Grid item xs={12} lg={props.img ? 8 : 12} flexDirection={"column"} sx={styles.contentBox}>
            <Box sx={[styles.titleBoxContainer, props.reverse ? {justifyContent: "flex-end"} : {}]}>
                <TitleBox title={props.title} dark={!(props.dark ?? false)}/>
            </Box>
            {props.children}

            <FloatingScroller
                id={props.id}
                nextScroll={props.nextScroll?.id ?? "top"}
                label={props.nextScroll?.label ?? ""}
                color={props.nextScroll?.color}
            /> 

        </Grid>
        {
            props.img ? <Grid item xs={12} lg={4} sx={styles.img} /> : null
        }

    </Grid>
}