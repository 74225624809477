import { Navigate } from "react-router-dom";
import IRoute from "routes/route";
import { HomePage } from "features/home";
import { AboutPage } from "features/about";
import { SolutionsPage } from "features/solutions";
import { ContactPage } from "features/contact";
import { ConsultancyPage } from "features/consultancy";
import { useCopy } from "data/copy";
import { PrivacyPolicyPage, CookiePolicyPage } from "features/privacy";

interface UseRoutes {
    tabs: IRoute[]
    pages: IRoute[]
    allRoutes: IRoute[]
};

export function useRoutes(): UseRoutes {
    const copy = useCopy();

    const tabs = [
        {
            name: copy.pages.home,
            path: "/",
            element: <HomePage />,
        },
        {
            name: copy.pages.about,
            path: "/about",
            element: <AboutPage />,
        },
        {
            name: copy.pages.consultancy,
            path: "/consultancy",
            element: <ConsultancyPage />,
        },
        {
            name: copy.pages.solutions,
            path: "/solutions",
            element: <SolutionsPage />,
        },
        {
            name: copy.pages.contact,
            path: "/contact",
            element: <ContactPage />,
        },
    ];

    const pages = [
        {
            name: copy.privacy.policyTitle,
            path: "/privacy",
            element: <PrivacyPolicyPage />
        },
        {
            name: copy.privacy.cookiePolicyTitle,
            path: "/cookies",
            element: <CookiePolicyPage />
        }
    ]

    const others = [
        {
            name: copy.pages.home,
            path: "/*",
            element: <Navigate to="/" />
        }
    ];

    return { tabs: tabs, pages: pages, allRoutes: tabs.concat(pages).concat(others)};
};
