import { Stack, Typography } from "@mui/material";
import Subtitle from "components/subtitle/Subtitle";
import { useCopy } from "data/copy";

interface CertificationProps {};

const styles = {
    mainStack: {

    },
    itemStack: {
        my: 1
    },
    titleStack: {
        justifyContent: "space-between"
    },
    title: {
        fontWeight: "bold"
    },
    body: {}
}

export default function Certification(props: CertificationProps) {
    const copy = useCopy();

    return <Stack direction="column" sx={styles.mainStack}>
        <Subtitle>{copy.consultancy.info.certification.title}</Subtitle>
        {
            copy.consultancy.info.certification.items.map((certification) => {
                return <Stack direction="column" sx={styles.itemStack} key={certification.certificate}>
                    <Typography sx={styles.title}>{certification.topic}</Typography>
                    <Stack direction="row" sx={styles.titleStack} spacing={8}>
                        <Typography sx={styles.body}>{certification.certificate}</Typography>
                        <Typography sx={styles.body}>{certification.year}</Typography>
                    </Stack>

                </Stack>
            })
        }
    </Stack>}