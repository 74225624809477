import Wrapper from "features/wrapper/components/Wrapper";
import { Grid } from "@mui/material";
import Projects from "../components/Projects";
import Info from "../components/Info";
import Tools from "../components/Tools";
import { useScroll } from "hooks/scroll";

interface ConsultancyPageProps {};


export default function ConsultancyPage(props: ConsultancyPageProps) {
    useScroll("info")
    return <Wrapper>
        <Grid container direction="column">
            <Grid item xs={12}><Info /></Grid>
            <Grid item xs={12}><Projects /></Grid>
            <Grid item xs={12}><Tools /></Grid>
        </Grid>
    </Wrapper>
};